import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Column } from 'primereact/column'
import { DataTable, DataTablePageEvent, DataTableSortEvent, SortOrder } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import extractDateFromFilename from '../Functions/extractDateFromFilename'
import getVisitTypeFromFilename from '../Functions/getVisitTypeFromFilename'
import useMemberGcsFiles, { GcsFile } from '../Hooks/useMemberGcsFiles'
import { Member } from '../Hooks/useMemberOdata'
import { LazyState } from '../Hooks/useWbapiInstance'
import DownloadButton from './DownloadButton'
import ViewButton from './ViewButton'

interface Props {
  member: Member | undefined
  visitTypes: string[] | undefined
}

export default function MemberDocs({ member, visitTypes }: Props) {
  const [viewing, setViewing] = useState<string | undefined>()
  const { data, isLoading, isError, downloadFile, getSignedUrl, requestUpdatedCCDs } = useMemberGcsFiles(member?.id)
  const toast = useRef<Toast>(null)
  const navigate = useNavigate()

  const [filesFormatted, setFilesFormatted] = useState<GcsFile[]>([])

  const requestUpdatedCCD = () => {
    if (!member?.id) return
    requestUpdatedCCDs.reset()
    requestUpdatedCCDs.mutate(member?.id)
  }

  useEffect(() => {
    if (requestUpdatedCCDs.isSuccess) {
      toast.current?.show({ severity: 'success', summary: 'Success!', detail: '' })
    } else if (requestUpdatedCCDs.isError) {
      console.log('Error requesting updated CCDs:', requestUpdatedCCDs.error)
      toast.current?.show({ severity: 'error', summary: 'Error!', detail: requestUpdatedCCDs.error.message ?? 'Contact Support' })
    }
  }, [requestUpdatedCCDs.isLoading])

  const header = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h2>{member?.fullName}'s Documents</h2>
        <div>
          {/* <Button
            type="button"
            className="mx-2"
            style={{ maxHeight: '5vh' }}
            size="small"
            icon="pi pi-download"
            severity="secondary"
            label="Request Updated CCD"
            onClick={requestUpdatedCCD}
            loading={requestUpdatedCCDs.isLoading}
          /> */}
          <Button
            type="button"
            className="mx-2"
            style={{ maxHeight: '5vh' }}
            size="small"
            icon="pi pi-chevron-left"
            label="Return to search"
            onClick={() => navigate('/search')}
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (data) {
      const formatted = data
        .filter((x) => !x.metadata.category.startsWith('CCD')) //filter ccds for now
        .map((file) => {
          const timeCreated = extractDateFromFilename(file.name, file.timeCreated)
          const visitType = getVisitTypeFromFilename(file.name, visitTypes)

          //instead of putting the word "CCD" on the screen/button etc... Use the words "Chart Summary (CCD)" so that the end user better understands what it is
          const category =
            file.metadata.category == 'ProgressNote'
              ? 'Progress Note'
              : file.metadata.category == 'CCD'
              ? 'Chart Summary (CCD)'
              : file.metadata.category

          return {
            ...file,
            timeCreated,
            visitType,
            metadata: { ...file.metadata, category },
          }
        })
      setFilesFormatted(formatted)
    }
  }, [data])

  const [state, setState] = useState<LazyState>({
    first: 0,
    rows: 10,
    page: 1,
    sortField: 'timeCreated',
    sortOrder: -1 as SortOrder,
    filters: {},
  })

  if (!member) return <></>

  const getSubstringAfterLastSlash = (inputString: string) => {
    const lastIndex = inputString.lastIndexOf('/')
    if (lastIndex !== -1) {
      return inputString.substring(lastIndex + 1)
    }
    // Handle the case when there's no slash in the string
    return inputString
  }

  const download = (rowData: GcsFile) => downloadFile.mutate(rowData.name)
  const view = (rowData: GcsFile) => {
    getSignedUrl.mutate(rowData.name, {
      onSuccess(data) {
        setViewing(data)
      },
    })
  }

  const onPage = (event: DataTablePageEvent) => setState({ ...state, ...event })

  const onSort = (event: DataTableSortEvent) => setState({ ...state, ...event })

  return (
    <Card>
      <DataTable
        loading={isLoading}
        size="small"
        header={header}
        value={filesFormatted}
        paginator
        sortField={state.sortField}
        sortOrder={state.sortOrder}
        onPage={onPage}
        onSort={onSort}
        stripedRows
        first={state.first}
        rows={state.rows}
        totalRecords={filesFormatted?.length ?? 0}
        emptyMessage="No files found."
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
        //   paginatorLeft={paginatorLeft}
        //       paginatorRight={paginatorRight}
      >
        <Column field="name" sortable header="Name" body={(rowData: GcsFile) => getSubstringAfterLastSlash(rowData.name)} />
        <Column
          align={'center'}
          field="timeCreated"
          sortable
          dataType="date"
          header="Date"
          body={(rowData: GcsFile) =>
            rowData.timeCreated == undefined ? 'Unknown' : rowData.timeCreated.toLocaleDateString('en-US', { timeZone: 'EST' })
          }
        />
        <Column
          align={'center'}
          field="visitType"
          header="Visit Type"
          sortable
          body={(rowData: GcsFile) => getVisitTypeFromFilename(rowData.name, visitTypes)}
        />
        <Column align={'center'} field="metadata.category" sortable header="Type" />
        <Column
          // header="View"
          align={'center'}
          body={(rowData: GcsFile) =>
            rowData.name.endsWith('xml') ? (
              <DownloadButton disabled={downloadFile.isLoading} onClick={() => download(rowData)} />
            ) : (
              <ViewButton disabled={getSignedUrl.isLoading} onClick={() => view(rowData)} />
            )
          }
        />
      </DataTable>
      <Dialog
        visible={!!viewing}
        modal
        maximizable
        className="iframe-container"
        style={{ width: '80vw', height: '70vh' }}
        breakpoints={{ '960px': '100vw' }}
        //header={headerElement}
        //footer={footerContent}
        //style={{ width: '50rem' }}
        onHide={() => {
          if (!viewing) return
          setViewing(undefined)
        }}
      >
        <iframe src={viewing} style={{ width: '100%', height: '98%' }} />
      </Dialog>
      <Toast ref={toast} />
    </Card>
  )
}
